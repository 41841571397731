export default [
  {
    path: '/dashboard/student',
    name: 'dashboard-student',
    component: () => import('@/views/dashboard/student/Student.vue'),
    meta: {
      resource: 'Dashboard Student',
      action: 'read',
    },
  },
  {
    path: '/dashboard/admin',
    name: 'dashboard-admin',
    component: () => import('@/views/dashboard/admin/Admin.vue'),
    meta: {
      resource: 'Dashboard Admin',
      action: 'read',
    },
  },
]
