export default [
  {
    path: '/announcement',
    name: 'announcement',
    component: () => import('@/views/announcement/List.vue'),
    meta: {
      resource: 'Announcement',
      action: 'read',
    },
  },
  {
    path: '/announcement/detail/:id',
    name: 'announcement-detail',
    component: () => import('@/views/announcement/Detail.vue'),
    meta: {
      resource: 'Announcement',
      action: 'read',
    },
  },
]
