export default [
  {
    path: '/inventory/facility',
    name: 'inventory-facility',
    component: () => import('@/views/inventory/FacilityList.vue'),
    meta: {
      resource: 'Inventory Facility',
      action: 'read',
    },
  },
  {
    path: '/inventory/tool',
    name: 'inventory-tool',
    component: () => import('@/views/inventory/ToolList.vue'),
    meta: {
      resource: 'Inventory Tool',
      action: 'read',
    },
  },
  {
    path: '/inventory/infrasturcture',
    name: 'inventory-infrastructure',
    component: () => import('@/views/infrastructure/Detail.vue'),
    meta: {
      resource: 'Inventory Infrastructure',
      action: 'read',
    },
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/category/List.vue'),
    meta: {
      resource: 'Category',
      action: 'read',
    },
  },
  {
    path: '/loan',
    name: 'loan',
    component: () => import('@/views/loan/List.vue'),
    meta: {
      resource: 'Loan',
      action: 'read',
    },
  },
  {
    path: '/loan/create',
    name: 'loan-create',
    component: () => import('@/views/loan/Form.vue'),
    meta: {
      resource: 'Loan',
      action: 'read',
    },
  },
  {
    path: '/loan/update/:id',
    name: 'loan-update',
    component: () => import('@/views/loan/Form.vue'),
    meta: {
      resource: 'Loan',
      action: 'read',
    },
  },
  {
    path: '/debt',
    name: 'debt',
    component: () => import('@/views/debt/Debt.vue'),
    meta: {
      resource: 'Debt',
      action: 'read',
    },
  },
]
