export default [
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/user/Profile.vue'),
        meta: {
            resource: 'Profile',
            action: 'read',
        },
    },

]